import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PToast = _resolveComponent("PToast")!
  const _component_HeaderComp = _resolveComponent("HeaderComp")!
  const _component_ContainerComp = _resolveComponent("ContainerComp")!
  const _component_FooterComp = _resolveComponent("FooterComp")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_PToast, { position: "top-right" }),
    _createVNode(_component_HeaderComp),
    _createVNode(_component_ContainerComp),
    _createVNode(_component_FooterComp)
  ]))
}