import {defineStore} from "pinia";
import {cardRequestType} from "@/types/CardRequestType";
import {formatToISO8601Date} from "@/services/DateTimeFormatter";

export const useSettingsStore = defineStore({
    id: "SettingsStore",
    state: () => ({
        apiToken: "" as string,
        creditScore: 0 as number,
        signRequest: false as boolean,
        requires_approval: null,
        backoffice_card_type_number: null,
        webhook_url: null,
        account_number_reference: null,
    }),
    getters: {
        getApiToken: (state) => state.apiToken,
        getCreditScore: (state) => state.creditScore,
        getSignRequest: (state) => state.signRequest
    },
    actions: {
        setApiToken(apiToken: string) {
            this.apiToken = apiToken;
        },
        addSettingsToData(data: cardRequestType) {
            data.credit_check = null;
            data.sign_request = null;
            if (this.requires_approval !== null) {
                data.requires_approval = this.requires_approval
            }
            if (this.backoffice_card_type_number !== null) {
                data.backoffice_card_type_number = this.backoffice_card_type_number
            }
            if (this.webhook_url !== null) {
                data.webhook_url = this.webhook_url
            }
            if (this.account_number_reference !== null) {
                data.customer.account_number_reference = this.account_number_reference
            }
            if (this.creditScore) {
                data.credit_check = {score: this.creditScore.toString()}
            }
            if (this.signRequest) {
                const date = new Date();
                const dateCopy = new Date(date.getTime());
                dateCopy.setMonth(dateCopy.getMonth() + 1);
                data.sign_request = {
                    mandate_reference: "mandate_reference",
                    mandate_start_date: formatToISO8601Date(date),
                    mandate_end_date: formatToISO8601Date(dateCopy)
                };
            }
        }
    },
    persist: {
        enabled: true,
    },
});
