export function formatToISO8601Date(date: Date) {
    const yyyy:number = date.getFullYear();
    const mm:number = date.getMonth() + 1; // Months start at 0!
    const dd:number = date.getDate();
    let formattedDay = dd.toString();
    let formattedMonth = mm.toString();

    if (dd < 10) {
        formattedDay = '0' + dd.toString();
    }
    if (mm < 10) {
        formattedMonth = '0' + mm.toString();
    }

    return yyyy + '-' + formattedMonth + '-' + formattedDay;
}
