import { defineStore } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import { cardRequestType } from "@/types/CardRequestType";
import { error, success } from '@/services/MyToastService';
import { uuid } from "vue3-uuid";

interface Error{
  field: null | string,
  message: null | string
}

function formatDateObjectToDateString(date: Date) {
  date = new Date(date)
  if (date instanceof Date) {
    return  date.getFullYear() + '-' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))
  }
}

export const useCardRequestStore = defineStore({
  id: "CardRequestStore",
  state: () => ({
    errors: [] as Error[],
  }),
  getters: {
  },
  actions: {
    postCardRequest: async function (deepData: cardRequestType) {
      const data = JSON.parse(JSON.stringify(deepData));
      const settingsStore = useSettingsStore();
      settingsStore.addSettingsToData(data);

      data.reference = uuid.v4()
      if ( data.customer_type == "B2C") {
        data.customer.company = null;
      }

      // format it in this function so we don't change the original working calendar component
      if (data.cards[0].subscription) {
        data.cards[0].subscription.subscription_start_date = formatDateObjectToDateString(data.cards[0].subscription.subscription_start_date);
        data.cards[0].subscription.subscription_end_date = formatDateObjectToDateString(data.cards[0].subscription.subscription_end_date);
        data.cards[0].subscription.subscription_direct_debit_start_date = formatDateObjectToDateString(data.cards[0].subscription.subscription_direct_debit_start_date);
      }

      this.errors = [];
      return fetch(process.env.VUE_APP_BASE_URL + "/api/third-party/external-card-requests", {
        mode: "cors",
        credentials: "include",
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json",
          "Authorization": "Bearer " + settingsStore.getApiToken
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response); // 2. reject instead of throw
        })
        .then((json) => {
          console.log(4321, json)

          success('Success', 'Card created');
        })
        .catch((errorResponse) => {
          if (errorResponse.status === 400) {
            errorResponse.json().then((json: any) => {
              this.errors.push({field: "", message: json.errors[0]})
            })

            error('Error', 'Validation error')
          }
          if (errorResponse.status === 401) {
            this.errors.push({field: "Unauthorized", message: null});
            error('Error', 'Unauthorized')
          }
          // if (errorResponse.status === 409) {
          //   // TODO
          // }
          if (errorResponse.status === 422) {
            errorResponse.json().then((json: any) => {
              this.errors = json.errors;
            })

            error('Error', 'Validation error')
          }
        });

    },
  },
});
