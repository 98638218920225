
import {email, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {defineComponent} from "vue";
import {useCardRequestStore} from "@/stores/CardRequestStore";
import {
  cardRequestType,
  cardRequestCardType,
  cardRequestCardSubscriptionType,
  cardRequestCardProductRestrictions
} from "@/types/CardRequestType";

export default defineComponent({
  setup: () => ({v$: useVuelidate(), cardRequestStore: useCardRequestStore()}),
  validationConfig: {
    $autoDirty: true,
  },
  computed: {
    isBusiness(): boolean {
      return this.form.customer_type == "B2B"
    },
    isFuelCard(): boolean {
      return this.form.card_type == "fuel_card"
    }
  },
  watch: {
    subscriptionOn: function () {
      this.form.cards = [];
      this.initializeCard();
    },
    isFuelCard: function (value) {
      if (value == false) {
        this.subscriptionOn = false;
      }
    }
  },
  methods: {
    handleSubmit: function (isFormValid: boolean) {
      this.submitted = true;
      if (!isFormValid) {
        return;
      }

      if (this.subscriptionOn === false) {
        this.form.cards.forEach(function (card) {
          card.subscription = null;
        })
      } else {
        this.form.cards = [];
        this.form.cards.push(this.card);
      }

      this.cardRequestStore.postCardRequest(this.form);
    },
    addCard: function () {
      this.form.cards.push(this.card);
      this.card = this.initializeCard();
    },
    removeCard: function (index: number) {
      this.form.cards.splice(index, 1);
    },
    initializeSubscription: function (): cardRequestCardSubscriptionType {
      return {
        subscription_start_date: "",
        subscription_end_date: "",
        subscription_direct_debit_start_date: "",
        subscription_product_backoffice_id: "",
        invoice_product_backoffice_id: "",
        invoice_period: "",
        accepted_direct_debit: true,
        payment_provider_redirect_url: ""
      };
    },
    initializeProductRestrictions: function(): cardRequestCardProductRestrictions {
      return {
        allowed_super_mlv: true,
        allowed_unleaded_98: true,
        allowed_diesel: true,
        allowed_lpg: true,
        allowed_mix: true,
        allowed_diesel_v_power: true,
        allowed_euro: true,
        allowed_gas_oil: true,
        allowed_petroleum: true,
        allowed_oil: true,
        allowed_wash: true,
        allowed_repair_service: true,
        allowed_food: true,
        allowed_tobacco: true,
        allowed_phone: true
      }
    },
    initializeCard: function (): cardRequestCardType {
      return {
        license_plate: "NL-74-AB",
        license_plate_country: "NL",
        reference: "test",
        allow_license_plate_recognition: false,
        subscription: this.initializeSubscription(),
        product_restrictions: this.initializeProductRestrictions()
      }
    }
  },
  data() {
    return {
      submitted: false,
      subscriptionOn: false,
      directDebitEnum: [
        {name: 'Maandelijks', value: 'monthly'},
        {name: 'Jaarlijks', value: 'yearly'},
      ],
      card: this.initializeCard() as cardRequestCardType,
      form: {
        reference: "",
        temporary_account_number: "temporary_account_number",
        customer_type: "B2B",
        card_type: "fuel_card",
        requires_approval: false,
        backoffice_card_type_number: 0,
        webhook_url: null,
        customer: {
          iban: "NL02ABNA0123456789",
          first_name: "Extendas",
          middle_name: "Card",
          last_name: "Request",
          phone_number: "0546456063",
          mobile_number: "0643204306",
          email: "e@e.nl",
          invoice_email: "",
          send_invoice_per_email: false,
          account_number_reference: null,
          address: {
            street: "Institutenweg",
            street_number: 28,
            street_number_addition: "",
            zip_code: "7521PK",
            city: "Enskedeeee",
            country: "NL",
          },
          company: {
            company_name: "",
            commercial_number: "",
            vat_number: "",
          }
        },
        credit_check: null,
        sign_request: null,
        cards: [],
      } as cardRequestType,
    };
  },
  validations() {
    return {
      form: {
        customer: {
          first_name: {required},
          last_name: {required},
          phone_number: {required},
          mobile_number: {required},
          email: {required, email},
          invoice_email: {email},
          iban: {required},
          address: {
            street: {required},
            street_number: {required},
            zip_code: {required},
            city: {required},
            country: {required}
          }
        }
      }
    }
  }
});
