import { ToastSeverity } from 'primevue/api';
import { app } from '@/main';

const lifeTime = 3000

export function success(title: string, body: string) {
  app.config.globalProperties.$toast.add({severity: ToastSeverity.SUCCESS, summary: title, detail: body, life: lifeTime})
}

export function info(title: string, body: string): void {
  app.config.globalProperties.$toast.add({severity: ToastSeverity.INFO, summary: title, detail: body, life: lifeTime})
}

export function error(title: string, body: string): void {
  app.config.globalProperties.$toast.add({severity: ToastSeverity.ERROR, summary: title, detail: body, life: lifeTime})
}