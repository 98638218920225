<template>
  <main>
    <div class="col-6 col-offset-3">
      <PBlockUI>
        <PCard>
          <template #title>Tankpas aanvraagformulier</template>
          <template #content>
            <RequestForm />
          </template>
          <template #footer> </template>
        </PCard>
      </PBlockUI>
    </div>
  </main>
</template>

<script>
import RequestForm from "./RequestForm.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ContainerComp",
  components: { RequestForm },
});
</script>

<style scoped></style>
