<template>
  <footer class="card-container align-bottom">
    <div class="block font-bold text-center p-4 border-round mb-3">
      <p>
        XCRS is een product van &copy;Extendas, Gevalideerde pasaanvraag en
        eenvoudig verwerken in &copy;FuelOffice!
      </p>
      <p>
        Meer weten?
        <a href="https://www.extendas.com" target="_blank">extendas.com</a> | of
        mail naar <a href="mailto:sales@extendas.com">sales@extendas.com</a>.
      </p>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "FooterComp",
});
</script>

<style scoped></style>
