
import {defineComponent} from "vue";
import {useSettingsStore} from "@/stores/SettingsStore";

export default defineComponent({
  setup() {
    const settingsStore = useSettingsStore();
    return {settingsStore};
  },
  name: "HeaderComp",
  methods: {
    openSettings: function () {
      this.display = !this.display;
    },
  },
  data() {
    return {
      display: false,
    };
  },
});
