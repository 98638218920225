
import { defineComponent } from "vue";
import HeaderComp from "./components/HeaderComp.vue";
import ContainerComp from "./components/ContainerComp.vue";
import FooterComp from "./components/FooterComp.vue";

export default defineComponent({
  name: "App",
  components: { HeaderComp, ContainerComp, FooterComp },
});
