import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";

import PBlockUI from "primevue/blockui";
import PButton from "primevue/button";
import PCard from "primevue/card";
import PCheckbox from "primevue/checkbox";
import PDropdown from "primevue/dropdown";
import PImage from "primevue/image";
import PInputText from "primevue/inputtext";
import PInputNumber from "primevue/inputnumber";
import PMenubar from "primevue/menubar";
import PRadioButton from "primevue/radiobutton";
import PDivider from "primevue/divider";
import PDialog from "primevue/dialog";
import PMessage from "primevue/message";
import PToast from "primevue/toast";
import PPanel from "primevue/panel";
import PTabView from "primevue/tabview";
import PTabPanel from "primevue/tabpanel";
import PCalendar from "primevue/calendar";
import ToastService from 'primevue/toastservice';

const pinia = createPinia();
pinia.use(piniaPersist);

export const app = createApp(App).use(pinia).use(PrimeVue).use(ToastService);

app.component("PBlockUI", PBlockUI);
app.component("PButton", PButton);
app.component("PCard", PCard);
app.component("PCheckbox", PCheckbox);
app.component("PDropdown", PDropdown);
app.component("PImage", PImage);
app.component("PInputNumber", PInputNumber);
app.component("PInputText", PInputText);
app.component("PMenubar", PMenubar);
app.component("PRadioButton", PRadioButton);
app.component("PDivider", PDivider);
app.component("PDialog", PDialog);
app.component("PMessage", PMessage);
app.component("PToast", PToast);
app.component("PPanel", PPanel);
app.component("PTabView", PTabView);
app.component("PTabPanel", PTabPanel);
app.component("PCalendar", PCalendar);

app.mount("#app");
